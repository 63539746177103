<template>
  <div>空</div>
</template>

<script>
import Index from "./login/index.vue";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    Index,
  },
};
</script>
<style lang="less" scoped>
</style>
