import request from '../request';

// 登录
export function login(data){
    return request({
        url:'/api/Login/login',
        method:'post',
        data:data
    })
}

// 注册
export function register(data){
    return request({
        url:'/api/Login/register',
        method:'post',
        data:data
    })
}

// 修改密码
export function rechargePassword(data){
    return request({
        url:'/api/user/rechargePassword',
        method:'post',
        data:data
    })
}

// 获取用户信息
export function getUserInfo(data){
    return request({
        url:'/api/user/getUserInfo',
        method:'post',
        data:data
    })
}

// 修改用户信息
export function editUserInfo(data){
    return request({
        url:'/api/user/editUserInfo',
        method:'post',
        data:data
    })
}

// 获取视频分类
export function getAllData(data){
    return request({
        url:'/api/VideoType/getAllData',
        method:'post',
        data:data
    })
}

// 根据视频分类获取视频
export function byTypeGetVideos(data){
    return request({
        url:'/api/Video/byTypeGetVideos',
        method:'post',
        data:data
    })
}

// 获取视频信息接口
export function getVideoInfo(data){
    return request({
        url:'/api/Video/getVideoInfo',
        method:'post',
        data:data
    })
}

// 获取题库数据
export function getAllDatas(data){
    return request({
        url:'/api/Question/getAllData',
        method:'post',
        data:data
    })
}

// 获取题库题目
export function selectQuestion(data){
    return request({
        url:'/api/Question/selectQuestion',
        method:'post',
        data:data
    })
}

// 提交练习接口
export function submitExercise(data){
    return request({
        url:'/api/Question/submitExercise',
        method:'post',
        data:data
    })
}

//保存错题接口

export function submitWrongExercise(data){
    return request({
        url:'/api/Practice/saveErrTopic',
        method:'post',
        data:data
    })
}


// 模拟考试
// 获取考试分类接口
export function examTypeGetAllData(data){
    return request({
        url:'/api/ExamType/getAllData',
        method:'post',
        data:data
    })
}

// 根据考试分类获取考试
export function examTypeByTypeGetData(data){
    return request({
        url:'/api/Examination/byTypeGetData',
        method:'post',
        data:data
    })
}

// 考试信息
export function examTypeGetExamInfo(data){
    return request({
        url:'/api/Examination/getExamInfo',
        method:'post',
        data:data
    })
}

// 获取模拟考试题目
export function examTypeGetExamTopic(data){
    return request({
        url:'/api/Examination/getExamTopic',
        method:'post',
        data:data
    })
}

//获取我的考试列表

export function getMyExamList(page,pagesize){
    return request(
        {
            url:'/api/Practice/getPractices',
            method:'post',
            data:{
                is_exam:[1],
                page:page,
                page_size:pagesize,
            },
        }
    )
}

// 保存/定时提交/交卷考试
export function examTypeSaveTopic(data){
    return request({
        url:'/api/Examination/saveTopic',
        method:'post',
        data:data
    })
}

// 能力评估报告
export function dataStatistics(data){
    return request({
        url:'/api/Practice/dataStatistics',
        method:'post',
        data:data
    })
}

//获取我的练习列表
export function apiGetMyPracticeList(page,pagesize){
    return request({
        url:'/api/Practice/getPractices',
        method:'post',
        data:{
            is_exam:[2,3],
            page:page,
            page_size:pagesize,

        }
    })
}

//获取我的错题列表
export function apiGetMyErrorProblemsList(){
    return request({
        url:'/api/Practice/getQuestionInfo',
        method:'post',
        data:{
            type:1,
        }
    })
}

//查看我的错题列表
export function apiSeeMyErrorProblemsList(id){
    return request({
        url:'/api/Practice/getQuestionExercises',
        method:'post',
        data:{
            id:id,
            type:1,
        }
    })
}

//查看我的笔记列表
export function apiSeeMyNoteList(id){
    return request({
        url:'/api/Practice/getQuestionExercises',
        method:'post',
        data:{
            id:id,
            type:2,
        }
    })
}

//查看我的收藏详情

export function apiSeeMyCollectList(id){
    return request({
        url:'/api/Practice/getQuestionExercises',
        method:'post',
        data:{
            id:id,
            type:3,
        }
    })
}

//获取我的笔记列表
export function apiGetMyNoteList(){
    return request({
        url:'/api/Practice/getQuestionInfo',
        method:'post',
        data:{
            type:2,
        }
    })
}

//获取我的笔记列表

export function apiGetMyCollectionList(){
    return request({
        url:'/api/Practice/getQuestionInfo',
        method:'post',
        data:{
            type:3,
        }
        
    })
}


//查看考试结果

export function apiSeeTestResult(data){
    return request({
        url:'/api/Question/getPracticeRes',
        method:'post',
        data:data,
    })
}

export function sendEmail(data){
    return request({
        url:'/api/Login/sendEmail',
        method:'post',
        data:data,
    })
}

export function editPassword(data){
    return request({
        url:'/api/Login/editPassword',
        method:'post',
        data:data,
    })
}



