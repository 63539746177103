import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './main.css';

// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';//样式文件一定要引入

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;

Vue.use(ElementUI);


// 
window.$bus = new Vue()


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
